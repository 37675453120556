import {
    Row,
    Col,
    Card,
    Descriptions,
    Image,
    Flex,
    Button,
    List,
    Avatar,
    Rate,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import "./AllCmsPage.css"
import fallback from "../../../images/fallback.webp";
// import fallback from "@/public/fallback.webp";

export default function ForWholeSalerPageView({ data }) {
    return (
        <Row gutter={[16, 24]} className="mt30">
            <Col span={24}>
                <Card
                    size="small"
                    title="For Wholesalers Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>For Wholesaler Title</b>,
                                children: <>{data?.for_wholesaler_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>For Wholesaler Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.for_wholesaler_description
                                        }}
                                    >
                                    </div>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>For Wholesaler Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {data?.for_wholesaler_image?.map((img) => (
                                            <Image width={100} src={img?.url} className="homeBannerImage" />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="OEM Infotainment Solutions Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>OEM Infotainment Solutions Title</b>,
                                children: <>{data?.oem_infotainment_solutions_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>OEM Infotainment Solutions Description</b>,
                                children: <div dangerouslySetInnerHTML={{ __html: data?.oem_infotainment_solutions_description }}></div>,
                                span: 1,
                            },
                            {
                                label: <b>OEM Infotainment Solutions</b>,
                                // children: (
                                //     <Flex align="center" wrap gap={12}>
                                //         {/* {data?.banner_description} */}
                                //         {data?.oem_info_solutions?.map((oemInfo) => (
                                //             <Card size="small">
                                //                 <Flex vertical justify="center" align="center" gap={8} className="trustImageContainer">
                                //                     <Image width={60} src={oemInfo?.image?.[0]?.url} className="trustImage" />
                                //                     <b>{oemInfo?.name}</b>
                                //                 </Flex>
                                //             </Card>
                                //         ))}
                                //     </Flex>
                                // ),

                                children: (
                                    <Row gutter={[16, 16]}>
                                        {data?.oem_info_solutions?.map((oemInfo) => (
                                            <Col span={12}>
                                                <Card size="small" style={{ height: "100%", textAlign: "center" }}>
                                                    <Flex vertical justify="center" align="center" gap={8} className="trustImageContainer">
                                                        <Image width={60} src={oemInfo?.image?.[0]?.url} className="trustImage" />
                                                        <div>
                                                            <b>{oemInfo?.name}</b>
                                                        </div>
                                                    </Flex>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>

            <Col span={24}>
                <Card
                    size="small"
                    title="Why You Choose Us Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Why You Choose Us Section Title</b>,
                                children: <>{data?.why_you_choose_us_section_title
                                }</>,
                                span: 1,
                            },
                            // {
                            //     label: <b>Why You Choose Us</b>,
                            //     children: (
                            //         <Flex align="center" wrap gap={12}>
                            //             {data?.why_you_choose?.map((choose) => (
                            //                 <Card size="small">
                            //                     <Flex vertical justify="center" align="center" gap={8} className="trustImageContainer">
                            //                         <Image width={60} src={choose?.image?.[0]?.url} className="trustImage" />
                            //                         <div>
                            //                             <b>{choose?.name}</b>
                            //                             <div
                            //                                 dangerouslySetInnerHTML={{
                            //                                     __html: choose?.description
                            //                                 }}
                            //                             >
                            //                             </div>
                            //                         </div>
                            //                     </Flex>
                            //                 </Card>
                            //             ))}
                            //         </Flex>
                            //     ),
                            //     span: 2,
                            // },
                            {
                                label: <b>Why You Choose Us</b>,
                                children: (
                                    <Row gutter={[16, 16]}>
                                        {data?.why_you_choose?.map((choose) => (
                                            <Col span={12}>
                                                <Card size="small" style={{ height: "100%", textAlign: "center" }}>
                                                    <Flex vertical justify="center" align="center" gap={8} className="trustImageContainer">
                                                        <Image width={60} src={choose?.image?.[0]?.url} className="trustImage" />
                                                        <div>
                                                            <b>{choose?.name}</b>
                                                        </div>
                                                    </Flex>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>

            <Col span={24}>
                <Card
                    size="small"
                    title="Seller Stories Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={3}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Seller Title</b>,
                                children: <>{data?.seller_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Seller Image</b>,
                                children: (
                                    <Flex align="center" wrap gap={12}>
                                        <Image
                                            width={100}
                                            src={data?.seller_image?.[0]?.url}
                                            className="homeAffiliationImage"
                                        />
                                    </Flex>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Seller Address</b>,
                                children: <>{data?.seller_address}</>,
                                span: 1,
                            },
                            {
                                label: <b>Seller DepartMent</b>,
                                children: <>{data?.seller_department}</>,
                                span: 1,
                            },
                            {
                                label: <b>Seller Number</b>,
                                children: <>{data?.seller_number}</>,
                                span: 1,
                            },
                            {
                                label: <b>Seller Email</b>,
                                children: <>{data?.seller_email}</>,
                                span: 1,
                            },
                            {
                                label: <b>Seller Description</b>,
                                children: <div dangerouslySetInnerHTML={{ __html: data?.seller_description }}></div>,
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Salesman Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    {/* <Descriptions
                        column={3}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Salesman</b>,
                                children: (
                                    <Flex align="center" wrap gap={12}>
                                        {data?.salesman?.map((s) => (
                                            <Card size="small">
                                                <Flex vertical justify="center" align="center" gap={8} className="trustImageContainer">
                                                    <Image width={60} src={s?.salesman_image?.[0]?.url} className="trustImage" />
                                                    <div>
                                                        <b>{s?.salesman_info}</b>
                                                        <b>{s?.salesman_name}</b>
                                                        <b>{s?.salesman_rating}</b>
                                                        <b>{s?.salesman_review}</b>


                                                    </div>
                                                </Flex>
                                            </Card>
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    /> */}
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        items={[
                            {
                                label: <b>Salesman</b>,
                                children: (
                                    <Row gutter={[16, 16]}>
                                        {data?.salesman?.map((s, index) => (
                                            <Col span={12} key={index}>
                                                <Card
                                                    size="small"
                                                    style={{ height: '100%' }}
                                                    bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}
                                                >
                                                    <Flex
                                                        vertical
                                                        justify="center"
                                                        align="center"
                                                        gap={14}
                                                    >
                                                        <Image
                                                            width={150}
                                                            src={s?.salesman_image?.[0]?.url || fallback}
                                                            preview={s?.salesman_image?.[0]?.url ? true : false}
                                                            className="trustImage"
                                                        />
                                                        <Descriptions
                                                            column={3}
                                                            size="small"
                                                            layout="vertical"
                                                            // layout="horizontal"
                                                            items={[
                                                                { label: <b>Name</b>, children: s?.salesman_name, span: 1 },
                                                                { label: <b>Info</b>, children: s?.salesman_info, span: 1 },
                                                                { label: <b>Rating</b>, children: s?.salesman_rating, span: 1 },
                                                                { label: <b>Review</b>, children: s?.salesman_review, span: 3 },
                                                            ]}
                                                        />
                                                    </Flex>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                ),
                                span: 1,
                            },
                        ]}
                    />

                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Get A Wholesale Account"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={2}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Wholesale Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {/* <Image width={150} src={data?.wholesale_image[0]?.url || ""} /> */}
                                        {data?.wholesale_image?.map((img) => (<Image width={150} src={img?.url} />))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Get A Wholesale Account Title</b>,
                                children: <>{data?.get_wholesale_account_title
                                }</>,
                                span: 1,
                            },
                            {
                                label: <b>Get A Wholesale Account Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.get_wholesale_account_description
                                        }}
                                    >

                                    </div>
                                ),
                                span: 2,
                            },

                        ]}
                    />
                </Card>
            </Col>

            <Col span={24}>
                <Card
                    size="small"
                    title="Search Engine Listing"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Meta Title</b>,
                                children: <>{data?.meta_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Meta Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.meta_description,
                                        }}
                                    >
                                        {/* {data?.banner_description} */}
                                    </div>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Banner Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.feature_image?.map((img) => (
                                            <Image width={100} src={img?.url} className="searchEngineImage" />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
        </Row>
    );
}
