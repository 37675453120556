import React from "react";
import { Form, Card, Col, Row, Input, Upload, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import UploadUtils from "../../utilities/uploadUtils";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

const SortableServiceItem = ({ id, index, field, remove }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      index
    });

  return (
    <Card
      size="small"
      title={`Service ${index + 1}`}
      ref={setNodeRef}
      style={{
        transform: `translate3d(${transform?.x || 0}px, ${
          transform?.y || 0
        }px, 0)`,
        cursor: "-moz-grab",
        transition,
        ...attributes.style,
        marginBottom: 24
      }}
      {...attributes}
      {...listeners}
      hoverable={true}
    >
      <Row gutter={16} style={{ margin: 0 }}>
        <Col span={12}>
          <Form.Item
            label="Service Name"
            name={[field.name, "service_name"]}
            rules={[
              {
                required: true,
                message: "This field is required"
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={"Link Url"}
            name={[field.name, "redirect_path"]}
            rules={[
              {
                required: true,
                message: "This field is required"
              }
            ]}
          >
           <Input addonBefore={process.env.REACT_APP_CUSTOMER_SITE_URL} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={
              <span>
                Service Image{" "}
                <Popover
                  placement="right"
                  title="W * H"
                  content="110 x 110"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            }
            name={[field.name, "service_image"]}
            {...UploadUtils.formItemProps}
            rules={[
              {
                required: true,
                message: "This field is required"
              }
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Upload {...UploadUtils.buttonPreviewProps}>
              <UploadUtils.CardContent />
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <i className="ri-drag-drop-line" style={{ fontSize: "18px" }}></i>
      </div>
    </Card>
  );
};

export default function SortableService({ data, setData, form }) {
  const serviceItems = data?.services?.map((item, index) => ({
    id: index?.toString(),
    ...item
  })) || [];

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 }
    })
  );

  const handleServiceDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active?.id !== over?.id) {
      const oldIndex = serviceItems?.findIndex(
        (item) => item?.id === active?.id
      );
      const newIndex = serviceItems?.findIndex((item) => item?.id === over?.id);

      if (oldIndex !== -1 && newIndex !== -1) {
        const updatedServices = arrayMove(serviceItems, oldIndex, newIndex);
        setData((prevData) => ({
          ...prevData,
          services: updatedServices?.map(({ id, ...rest }) => rest)
        }));

        form.setFieldsValue({
          services: updatedServices?.map(({ id, ...rest }) => rest)
        });
      }
    }
  };

  return (
    <Card size="small" style={{ backgroundColor: "#fafafa" }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleServiceDragEnd}
      >
        <SortableContext
          items={serviceItems?.map((item) => item?.id)}
          strategy={verticalListSortingStrategy}
        >
          <Form.List name="services" initialValues={data?.services}>
            {(fields, { add, remove }) => (
              <div
                style={{
                  display: "flex",
                  gap: 30,
                  flexDirection: "row",
                  flexWrap: "wrap"
                }}
              >
                {fields?.map((field, index) => (
                  <SortableServiceItem
                    key={serviceItems[index]?.id}
                    id={serviceItems[index]?.id}
                    index={index}
                    field={field}
                    remove={remove}
                  />
                ))}
              </div>
            )}
          </Form.List>
        </SortableContext>
      </DndContext>
    </Card>
  );
}
