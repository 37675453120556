import { Spin } from 'antd';
import React from 'react';

export default function SuspenseSpin() {
  return (
    <div className="suspenece-spin">
      <Spin style={{ color: '#b12525' }} />
    </div>
  );
}
