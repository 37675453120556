import {
    Row,
    Col,
    Card,
    Image,
    Flex,
    Button,
    Upload,
    Table,
    Divider,
    Modal,
    Descriptions,
    Select,
    Tag,
    Popover,
    Popconfirm,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import "./AllCmsPage.css"

export default function VerifiedInstallerPageView({ data }) {
    return (
        <Row gutter={[16, 24]} className="mt30">
            <Col span={24}>
                <Card
                    size="small"
                    title="Verified Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Verified Title</b>,
                                children: <>{data?.verified_installer?.verified_installer_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Verified Description</b>,
                                children: <div dangerouslySetInnerHTML={{ __html: data?.verified_installer?.verified_installer_description }}></div>,
                                span: 1,
                            },

                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="With Verified Installation Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        items={[
                            {
                                label: <b>With Verified Installation Point</b>,
                                children: <>
                                    <Flex vertical>
                                        <p>{data?.with_verified_installation?.with_verified_installation_title}</p>
                                        <div>
                                            {data?.with_verified_installation?.with_verified_installation_point.map((point) => (
                                                <p>{point?.verified_installation_point}</p>
                                            ))
                                            }
                                        </div>
                                    </Flex>
                                </>,
                                span: 1,
                            }
                        ]}
                    />


                </Card>
            </Col>

            <Col span={24}>
                <Card
                    size="small"
                    title="Verified Installer Location Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                    style={{ width: "100%" }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        items={[
                            {
                                label: <b>Location Title</b>,
                                children: <>
                                    <Flex vertical style={{ width: "100%" }}>
                                        <p>{data?.location_title}</p>
                                        <Table
                                            rowHoverable={false}
                                            style={{ width: "100%" }}
                                            // bordered
                                            columns={[
                                                {
                                                    title: "Location Title",
                                                    dataIndex: "location_title",
                                                    render: (value, record, index) => {
                                                        return (
                                                            <div>
                                                                <span>{value}</span>
                                                            </div>
                                                        );
                                                    },
                                                    fixed: "left",
                                                    width: 180
                                                },
                                                {
                                                    title: "Phone Number & Email",
                                                    dataIndex: ["phone_number", "email"],
                                                    key: "email",
                                                    render: (record, row) => {
                                                        return (
                                                            <Flex vertical>
                                                                <span>{row["phone_number"]}</span>
                                                                <span>{row["email"]}</span>
                                                            </Flex>
                                                        )
                                                    },
                                                    width: 200
                                                },
                                                {
                                                    title: "Lat/Lan",
                                                    dataIndex: ["lat", "lng"],
                                                    key: "email",
                                                    render: (record, row) => {
                                                        return (
                                                            <Flex vertical>
                                                                <span>{row["lat"]}</span>
                                                                <span>{row["lng"]}</span>
                                                            </Flex>
                                                        )
                                                    },
                                                    width: 120
                                                },

                                                {
                                                    title: "Website Name",
                                                    dataIndex: "website_url",
                                                    width: 220
                                                },
                                                {
                                                    title: "Location Address",
                                                    dataIndex: "location_address",
                                                    width: 200
                                                },
                                                {
                                                    title: "Status",
                                                    dataIndex: "status",
                                                    width: 50,
                                                    render: (value, record, index) => {
                                                        return (
                                                            <Tag
                                                                color={
                                                                    (value ?? "").toLowerCase() === "active"
                                                                        ? "green"
                                                                        : (value ?? "").toLowerCase() === "inactive"
                                                                            ? "red"
                                                                            : "default"
                                                                }
                                                            >
                                                                {value}
                                                            </Tag>
                                                        );
                                                    },
                                                    fixed: "right"
                                                },

                                            ]}
                                            dataSource={data?.verified_location}
                                            scroll={{ x: "max-content" }}

                                        // pagination={{
                                        //     pageSize: pageSize,
                                        //     total: locations?.length,
                                        //     current,
                                        //     onChange: (page, pageSize) => {
                                        //         setCurrent(page);
                                        //         setPageSize(pageSize);
                                        //     },
                                        // }}
                                        />
                                    </Flex>
                                </>,
                                span: 1,
                            }
                        ]}
                    />


                </Card>
            </Col>

            <Col span={24}>
                <Card
                    size="small"
                    title="Search Engine Listing"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Meta Title</b>,
                                children: <>{data?.meta_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Meta Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.meta_description,
                                        }}
                                    >
                                        {/* {data?.banner_description} */}
                                    </div>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Banner Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.feature_image?.map((img) => (
                                            <Image width={100} src={img?.url} className="searchEngineImage" />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
        </Row>
    );
}
