import {
  PiUserListFill,
  PiUserSquareDuotone,
  PiShoppingBagDuotone,
  PiGearSixDuotone,
  PiBasketDuotone,
  PiShoppingCartDuotone,
} from "react-icons/pi";
import {
  MdOutlineInventory,
  MdOutlineReviews,
} from "react-icons/md";
import { BiCartDownload } from "react-icons/bi";
import routes from "./routes";
import roleAndPermissions from "./permissions";
import { BiSolidDiscount } from "react-icons/bi";
import { TbGiftCard } from "react-icons/tb";
import { RiFileChartLine } from "react-icons/ri";

const menus = [

  {
    label: "Items",
    requiredPermissions: [
      roleAndPermissions.ProductManagement.create,
      roleAndPermissions.ProductManagement.update,
      roleAndPermissions.ProductManagement.delete,
      roleAndPermissions.ProductManagement.view,
    ],
    type: "group",
    children: [
      {
        key: routes.PRODUCTS.path,
        label: "Products",
        icon: <PiShoppingBagDuotone />,
        requiredPermissions: [
          roleAndPermissions.ProductManagement.create,
          roleAndPermissions.ProductManagement.update,
          roleAndPermissions.ProductManagement.delete,
          roleAndPermissions.ProductManagement.view,
        ],
      },
      {
        key: routes.PRODUCTSREVIEWS.path,
        label: "Products Reviews",
        icon: <MdOutlineReviews />,
        requiredPermissions: [
          roleAndPermissions.ProductReviews.create,
          roleAndPermissions.ProductReviews.update,
          roleAndPermissions.ProductReviews.delete,
          roleAndPermissions.ProductReviews.view,
        ],
      },
      {
        key: "product-meta",
        label: "Settings",
        icon: <PiGearSixDuotone />,
        requiredPermissions: [
          roleAndPermissions.ProductManagement.create,
          roleAndPermissions.ProductManagement.update,
          roleAndPermissions.ProductManagement.delete,
          roleAndPermissions.ProductManagement.view,
        ],
        labelOnly: true,
        children: [
          {
            key: routes.PRODUCT_CATEGORY.path,
            label: "Categories",
            requiredPermissions: [
              roleAndPermissions.ProductCategory.create,
              roleAndPermissions.ProductCategory.update,
              roleAndPermissions.ProductCategory.delete,
              roleAndPermissions.ProductCategory.view,
            ],
            openMenuKeys: ["product-meta"],
          },
          {
            key: routes.PRODUCT_CATALOG.path,
            label: "Catalogs",
            requiredPermissions: [
              roleAndPermissions.ProductCatalog.create,
              roleAndPermissions.ProductCatalog.update,
              roleAndPermissions.ProductCatalog.delete,
              roleAndPermissions.ProductCatalog.view,
            ],
            openMenuKeys: ["product-meta"],
          },
          {
            key: routes.PRODUCT_BRAND.path,
            label: "Brands",
            requiredPermissions: [
              roleAndPermissions.ProductBrand.create,
              roleAndPermissions.ProductBrand.update,
              roleAndPermissions.ProductBrand.delete,
              roleAndPermissions.ProductBrand.view,
            ],
            openMenuKeys: ["product-meta"],
          },
          {
            key: routes.PRODUCT_COMPATIBILITY.path,
            label: "Compatibility",
            requiredPermissions: [
              roleAndPermissions.ProductCompatibility.create,
              roleAndPermissions.ProductCompatibility.update,
              roleAndPermissions.ProductCompatibility.delete,
              roleAndPermissions.ProductCompatibility.view,
            ],
          },
          {
            key: routes.ORDERS_ADDON.path,
            label: "Add-ons",
            requiredPermissions: [
              roleAndPermissions.OrderAddOn.create,
              roleAndPermissions.OrderAddOn.update,
              roleAndPermissions.OrderAddOn.delete,
              roleAndPermissions.OrderAddOn.view,
            ],
          },
        ],
      },
      {
        key: routes.GIFT_CARD_LIST.path,
        label: "Gift Card",
        icon: <TbGiftCard />,
        requiredPermissions: [
          roleAndPermissions.GiftCardManagement.create,
          roleAndPermissions.GiftCardManagement.update,
          roleAndPermissions.GiftCardManagement.delete,
          roleAndPermissions.GiftCardManagement.view,
        ],
      },
      {
        key: routes.DISCOUNTS.path,
        label: "Discounts",
        icon: <BiSolidDiscount />,
        requiredPermissions: [
          roleAndPermissions.DiscountManagement.create,
          roleAndPermissions.DiscountManagement.update,
          roleAndPermissions.DiscountManagement.delete,
          roleAndPermissions.DiscountManagement.view,
        ],
      },
    ],
  },
  {
    icon: <PiUserListFill />,
    label: "Purchase",
    requiredPermissions: [
      roleAndPermissions.Vendor.create,
      roleAndPermissions.Vendor.update,
      roleAndPermissions.Vendor.delete,
      roleAndPermissions.Vendor.view,
    ],
    type: "group",
    children: [
      {
        key: routes.VENDORS.path,
        icon: <PiBasketDuotone />,
        label: "Vendors",
        requiredPermissions: [
          roleAndPermissions.Vendor.create,
          roleAndPermissions.Vendor.update,
          roleAndPermissions.Vendor.delete,
          roleAndPermissions.Vendor.view,
        ],
      },
    ],
  },
  {
    label: "Sales",
    requiredPermissions: [
      roleAndPermissions.CustomerManagement.view,
      roleAndPermissions.CustomerManagement.create,
      roleAndPermissions.CustomerManagement.update,
      roleAndPermissions.CustomerManagement.delete,
      roleAndPermissions.AbandonedCheckouts.create,
      roleAndPermissions.AbandonedCheckouts.update,
      roleAndPermissions.AbandonedCheckouts.delete,
      roleAndPermissions.AbandonedCheckouts.view,
    ],
    type: "group",
    children: [
      {
        key: routes.CUSTOMERS.path,
        icon: <PiUserSquareDuotone />,
        label: "Customers",
        requiredPermissions: [
          roleAndPermissions.CustomerManagement.view,
          roleAndPermissions.CustomerManagement.create,
          roleAndPermissions.CustomerManagement.update,
          roleAndPermissions.CustomerManagement.delete,
        ],
      },
      {
        key: routes.ORDERS.path,
        icon: <PiShoppingCartDuotone />,
        label: "Orders",
        requiredPermissions: [
          roleAndPermissions.Order.create,
          roleAndPermissions.Order.update,
          roleAndPermissions.Order.delete,
          roleAndPermissions.Order.view,
        ],
      },
      {
        key: routes.ABANDONEDCHECKOUTS.path,
        icon: <BiCartDownload />,
        label: "Abandoned Checkouts",
        requiredPermissions: [
          roleAndPermissions.AbandonedCheckouts.view,
          roleAndPermissions.AbandonedCheckouts.create,
          roleAndPermissions.AbandonedCheckouts.update,
          roleAndPermissions.AbandonedCheckouts.delete,
        ],
      },
    
    ],
  },
  {
    icon: <MdOutlineInventory />,
    label: "Reports",
    requiredPermissions: [
      roleAndPermissions.Reports.viewCompatibility,
    ],
    type: "group",
    children: [
      {
        key:routes.REPORTS.path,
        icon: <RiFileChartLine />,
        label: "Reports",
        requiredPermissions: [
          roleAndPermissions.Reports.viewCompatibility,
        ],
      },
    ],
  },
  {
    icon: <MdOutlineInventory />,
    label: "CMS",
    requiredPermissions: [
      roleAndPermissions.PageManagement.view,
      roleAndPermissions.PageManagement.create,
      roleAndPermissions.PageManagement.update,
      roleAndPermissions.PageManagement.delete,
    ],
    type: "group",
    children: [
      {
        key: routes.CMSPAGE.path,
        // icon: <PiStackDuotone />,
        label: "CMS Pages",
        requiredPermissions: [
          roleAndPermissions.PageManagement.view,
          roleAndPermissions.PageManagement.create,
          roleAndPermissions.PageManagement.update,
          roleAndPermissions.PageManagement.delete,
        ],
      },
      {
        key: routes.BLOGS.path,
        // icon: <PiStackDuotone />,
        label: "Blogs",
        requiredPermissions: [
          roleAndPermissions.PageManagement.view,
          roleAndPermissions.PageManagement.create,
          roleAndPermissions.PageManagement.update,
          roleAndPermissions.PageManagement.delete,
        ],
      },
    ],
  },
];

export default menus;
