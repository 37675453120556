import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  DatePicker,
  InputNumber,
  Upload,
  Popover,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import SortDraggableList from "../../../components/SortDraggableList";
import roleAndPermissions from "../../../constants/permissions"
import AuthChecker from "../../../components/authChecker"
import PrivacyPolicyPageView from "../pages-view/PrivacyPolicyPageView";

const { TextArea } = Input;

const SortablePrivacyPolicy = ({ data, setData, form, mode }) => {
  const policyItems =
    data?.privacypolicys?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderPrivacyPolicyContent = (field) => (
    <div>
      <Form.Item
        label="Title"
        name={[field.name, "name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Privacy Policy Description"
        name={[field.name, "description"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <ReactQuill theme="snow" rows={4} readOnly={mode === "View" ? true : false} />
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={policyItems}
      renderContent={renderPrivacyPolicyContent}
      setData={setData}
      form={form}
      formField="privacypolicys"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Privacy Policy"
    />
  );
};

const PrivacyPolicyForm = ({ mode, formFor, editLayout, title }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(formFor?.en?.data);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];

      setData(values);
      await editLayout(values, "Privacy Policy", seo_setting);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  if (mode !== "View") {
    return (
      <Form
        form={form}
        disabled={mode === "View" || loading === true}
        layout="vertical"
        initialValues={data}
        onFinish={(values) => handleSubmit(values)}
      >
        <PageHeader title={`${title?.replace("-", " ")} Page`} />
        <Row gutter={16} className="mt30">
          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Privacy & Policy Main Title" type="inner">
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Card>
          </Col>

          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Privacy Policy Section" type="inner">
              <SortablePrivacyPolicy data={data} setData={setData} form={form} mode={mode} />
              {/* <Form.List name="privacypolicys">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 30,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {" "}
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`Privacy Policy ${index + 1}`}
                        key={field.key}
                        extra={
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Title"
                          name={[field.name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Terms & Conditions Description"
                          name={[field.name, "description"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <ReactQuill theme="snow" rows={4} />
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Term & Conditions
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List> */}
            </Card>
          </Col>
          <AuthChecker notPermissionCase={
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Search Engine Listing" type="inner">
                <Form.Item
                  label="Meta Title"
                  name="meta_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="Meta Description"
                  name="meta_description"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <TextArea disabled />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Feature Image{" "}
                      <Popover
                        placement="right"
                        title="W * H"
                        content="1200 x 675 OR 1600 x 900"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </span>
                  }
                  name="feature_image"
                  {...UploadUtils.formItemProps}
                  wrapperCol={{ span: 24 }}
                >
                  <Upload {...UploadUtils.buttonPreviewProps} disabled>
                    <UploadUtils.CardContent />
                  </Upload>
                </Form.Item>
              </Card>
            </Col>} permissions={[roleAndPermissions.PageManagement.createSEO, roleAndPermissions.PageManagement.updateSEO]}>

            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Search Engine Listing" type="inner">
                <Form.Item
                  label="Meta Title"
                  name="meta_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Meta Description"
                  name="meta_description"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Feature Image{" "}
                      <Popover
                        placement="right"
                        title="W * H"
                        content="1200 x 675 OR 1600 x 900"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </span>
                  }
                  name="feature_image"
                  {...UploadUtils.formItemProps}
                  wrapperCol={{ span: 24 }}
                >
                  <Upload {...UploadUtils.buttonPreviewProps}>
                    <UploadUtils.CardContent />
                  </Upload>
                </Form.Item>
              </Card>
            </Col>
          </AuthChecker>
          <Col span={24}>
            <Flex justify="end" style={{ padding: 24, paddingRight: 0 }} gap={16}>
              <Form.Item noStyle>
                <Button type="primary" htmlType="submit" loading={loading}
                  disabled={loading}>
                  Update Page
                </Button>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Form>
    );
  }

  if (mode === "View") {
    return (
      <> <PageHeader title={`${title?.replace("-", " ")} Page`} />
        <PrivacyPolicyPageView data={data} />
      </>
    );
  }
};

export default PrivacyPolicyForm;
