import { Spin } from "antd";
import Cookies from "js-cookie";
import React from "react";
import endpoints from "../constants/endpoints";
import requests from "../utilities/api";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Provider } from '@auth0/auth0-react';

let UserContext = React.createContext({});

const Auth0ProviderWrapper = ({ onAuth0Loaded }) => {
  const { logout } = useAuth0();

  React.useEffect(() => {
    onAuth0Loaded(logout);
  }, [logout, onAuth0Loaded]);

  return null; 
};

export default function withUserContext(ChildComponent) {
  return class extends React.Component {
    state = {
      user: {
        id: null,
        name: null,
        first_name: null,
        last_name: null,
        email: null,
        email_verified_at: null,
        created_at: null,
        updated_at: null,
      },
      status: "unknown",
      auth0Logout: null, 
      fetchUser: async (token) => {
        const api = requests();
        this.setState({ status: "loading" });
        let response = await api.get(
          endpoints.me,
          {},
          true,
          [undefined, null, ""].includes(token)
            ? {}
            : {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
        );
        if (response.status === "Success") {
          this.setState(
            {
              user: {
                ...response.data,
                token: token ?? Cookies.get("infotainment-token"),
              },
            },
            () => {
              this.setState({ status: "loggedin" });
            }
          );
        } else {
          Cookies.remove("infotainment-token");
          this.setState({ status: "not_loggedin" });
        }
      },
      logout: async () => {
        if (!this.state.auth0Logout) {
          console.error("Auth0 logout function is not available.");
          return;
        }

        const api = requests();
        this.setState({ status: "loading" });
        let response = await api.get(endpoints.logout, {}, true);
        if (response.status === "Success") {
          localStorage.removeItem('access_token');
          Cookies.remove("infotainment-token");
          this.state.auth0Logout({ returnTo: `${process.env.REACT_APP_WEBSITE_URL}/login` });
          this.setState({ status: "not_loggedin" });
        } else {
          this.setState({ status: "loggedin" });
        }
      },
    };

    setAuth0Logout = (logoutFunc) => {
      this.setState({ auth0Logout: logoutFunc });
    };

    componentDidMount() {
      let token = Cookies.get("infotainment-token");
      if (![undefined, null, ""].includes(token)) {
        this.state.fetchUser(token);
      } else {
        this.setState({ status: "not_loggedin" });
      }
    }

    render() {
      return (
        <>
          {/* <Auth0Provider
            domain="dev-a3uzcuf41ns40wq3.us.auth0.com"
            clientId="dos4I8UbJHSnrYsi3kL8BBbY2YObP33X"
            authorizationParams={{
              redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}/login`
            }}
          > */}
          <Auth0Provider
           domain={process.env.REACT_APP_AUTH0_DOMAIN}
           clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
           authorizationParams={{
             prompt: "login",
             redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}/login`
           }}
          >
            <Auth0ProviderWrapper onAuth0Loaded={this.setAuth0Logout} />
            <UserContext.Provider value={this.state}>
              <Spin style={{ color: '#b12525' }} spinning={this.state.status === "loading"}>
                <ChildComponent {...this.props} />
              </Spin>
            </UserContext.Provider>
          </Auth0Provider>
        </>
      );
    }
  };
}

export { UserContext };
