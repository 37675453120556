import {
    Row,
    Col,
    Card,
    Descriptions,
    Image,
    Flex,
    Button,
    List,
    Avatar,
    Rate,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import "./AllCmsPage.css"

export default function HeaderPageView({ data }) {
    return (
        <Row gutter={[16, 24]} className="mt30">
            <Col span={24}>
                <Card
                    size="small"
                    title="Customer Care"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Customer Care Number</b>,
                                children: <>{data?.phone_number}</>,
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Header Items"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Header Item</b>,
                                // children: (
                                //     <Flex align="center" wrap gap={12}>
                                //         {/* {data?.banner_description} */}
                                //         {data?.items?.map((item) => (
                                //             <Card size="small">
                                //                 <Flex
                                //                     justify="center"
                                //                     align="center"
                                //                     gap={8}
                                //                     style={{ width: "100%" }}
                                //                 >
                                //                     <Flex vertical>
                                //                         <b>{item?.item_name}</b>
                                //                         {
                                //                             item?.item_type === "link" && (
                                //                                 item?.link_type === "internal" ? (
                                //                                     <Link
                                //                                         to={`${process.env.REACT_APP_CUSTOMER_SITE_URL}${item?.redirect_path}`}
                                //                                         target="_blank"
                                //                                     >
                                //                                         <Button size="small" type="link" style={{ padding: 0 }}>
                                //                                             {item?.redirect_path}
                                //                                         </Button>
                                //                                     </Link>
                                //                                 ) : item?.link_type === "external" ? (
                                //                                     <Link
                                //                                         to={`${item?.redirect_path}`}
                                //                                         target="_blank"
                                //                                     >
                                //                                         <Button size="small" type="link" style={{ padding: 0 }}>
                                //                                             {item?.redirect_path}
                                //                                         </Button>
                                //                                     </Link>
                                //                                 ) : null
                                //                             )
                                //                         }
                                //                         {
                                //                             item?.item_type === "dropdown" &&
                                //                             item?.options?.map((opt) => {
                                //                                 if (opt?.link_type === "internal") {
                                //                                     return (
                                //                                         <Link
                                //                                             to={process.env.REACT_APP_CUSTOMER_SITE_URL + opt?.url}
                                //                                             target="_blank"
                                //                                         >
                                //                                             <Button
                                //                                                 size="small"
                                //                                                 type="link"
                                //                                                 style={{ padding: 0 }}
                                //                                             >
                                //                                                 {" "}
                                //                                                 {opt?.url}
                                //                                             </Button>
                                //                                         </Link>
                                //                                     )
                                //                                 } else if (opt?.link_type === "external") {
                                //                                     return (
                                //                                         <Link
                                //                                             to={opt?.url}
                                //                                             target="_blank"
                                //                                         >
                                //                                             <Button
                                //                                                 size="small"
                                //                                                 type="link"
                                //                                                 style={{ padding: 0 }}
                                //                                             >
                                //                                                 {" "}
                                //                                                 {opt?.url}
                                //                                             </Button>
                                //                                         </Link>
                                //                                     )
                                //                                 }
                                //                             })
                                //                         }
                                //                     </Flex>
                                //                 </Flex>
                                //             </Card>
                                //         ))}
                                //     </Flex>
                                // ),
                                children: (
                                    <Row gutter={[16, 16]}>
                                        {data?.items?.map((item) => (
                                            <Col span={8}>
                                                <Card size="small" style={{ height: "100%" }}>
                                                    <Flex align="center" gap={8}>
                                                        <Flex vertical>
                                                            <b>{item?.item_name}</b>
                                                            {
                                                                item?.item_type === "link" && (
                                                                    item?.link_type === "internal" ? (
                                                                        <Link
                                                                            to={`${process.env.REACT_APP_CUSTOMER_SITE_URL}${item?.redirect_path}`}
                                                                            target="_blank"
                                                                        >
                                                                            <Button size="small" type="link" style={{ padding: 0 }}>
                                                                                {item?.redirect_path}
                                                                            </Button>
                                                                        </Link>
                                                                    ) : item?.link_type === "external" ? (
                                                                        <Link
                                                                            to={`${item?.redirect_path}`}
                                                                            target="_blank"
                                                                        >
                                                                            <Button size="small" type="link" style={{ padding: 0 }}>
                                                                                {item?.redirect_path}
                                                                            </Button>
                                                                        </Link>
                                                                    ) : null
                                                                )
                                                            }
                                                            {
                                                                item?.item_type === "dropdown" &&
                                                                item?.options?.map((opt) => {
                                                                    if (opt?.link_type === "internal") {
                                                                        return (
                                                                            <Link
                                                                                to={process.env.REACT_APP_CUSTOMER_SITE_URL + opt?.url}
                                                                                target="_blank"
                                                                            >
                                                                                <Button
                                                                                    size="small"
                                                                                    type="link"
                                                                                    style={{ padding: 0 }}
                                                                                >
                                                                                    {" "}
                                                                                    {opt?.url}
                                                                                </Button>
                                                                            </Link>
                                                                        )
                                                                    } else if (opt?.link_type === "external") {
                                                                        return (
                                                                            <Link
                                                                                to={opt?.url}
                                                                                target="_blank"
                                                                            >
                                                                                <Button
                                                                                    size="small"
                                                                                    type="link"
                                                                                    style={{ padding: 0 }}
                                                                                >
                                                                                    {" "}
                                                                                    {opt?.url}
                                                                                </Button>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </Flex>
                                                    </Flex>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Search Engine Listing"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Meta Title</b>,
                                children: <>{data?.meta_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Meta Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.meta_description,
                                        }}
                                    >
                                        {/* {data?.banner_description} */}
                                    </div>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Banner Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.feature_image?.map((img) => (
                                            <Image width={100} src={img?.url} className="searchEngineImage" />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
        </Row>
    );
}
