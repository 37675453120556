import { useEffect } from "react";

const AffirmCheckout = () => {
  useEffect(() => {
    // Step 1: Define _affirm_config before loading Affirm.js
    window._affirm_config = {
      public_api_key: "6ZNZMDX0X9N2LTXP", // Replace with your actual API key
      script: "https://sandbox.affirm.com/js/v2/affirm.js",
      mode: "sandbox", // Change to "production" for live use
    };

    // Step 2: Load Affirm.js dynamically
    const affirmScript = document.createElement("script");
    affirmScript.src = window._affirm_config.script;
    affirmScript.async = true;
    affirmScript.onload = () => {
      window.affirm.ui.ready();
    };

    document.body.appendChild(affirmScript);
  }, []);

  const handleAffirmCheckout = () => {
    if (!window.affirm) {
      console.error("Affirm script not loaded yet.");
      return;
    }

    window.affirm.checkout({
      merchant: {
        user_cancel_url: "https://www.affirm.com",
        user_confirmation_url: "https://www.affirm.com",
        user_confirmation_url_action: "GET",
        merchant: "Affirm Demo Inc.",
      },
      shipping: {
        name: { first: "John", last: "Doe" },
        address: {
          line1: "225 Bush Street",
          line2: "Floor 16",
          city: "San Francisco",
          state: "CA",
          country: "US",
          zipcode: "94104",
        },
        email: "john.doe@example.com",
        phone_number: "4155555555",
      },
      billing: {
        name: { first: "John", last: "Doe" },
        address: {
          line1: "225 Bush Street",
          line2: "Floor 16",
          city: "Beckley",
          state: "CA",
          country: "US",
          zipcode: "24801",
        },
        email: "john.doe@example.com",
        phone_number: "4155555555",
      },
      metadata: { mode: "modal" },
      items: [
        {
          sku: "123456789",
          display_name: "Affirm Scarf",
          unit_price: 60000,
          qty: 1,
        },
      ],
      total: 60000,
    });

    window.affirm.checkout.open({
      onFail: (error) => {
        console.error("Affirm Checkout Failed:", error);
      },
      onSuccess: (response) => {
        alert(
          "Affirm checkout successful! Checkout token: " +
            response.checkout_token
        );

        const checkoutToken = response.checkout_token;

        console.log('Checkout Response',response)

        // // Send checkout_token to backend
        // fetch("/charge", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
        //       .content,
        //   },
        //   body: JSON.stringify({ checkout_token: checkoutToken }),
        // })
        //   .then((res) => res.json())
        //   .then((data) => {
        //     console.log("Charge Response:", data);
        //   })
        //   .catch((error) => {
        //     console.error("Error processing charge:", error);
        //   });
      },
    });
  };

  return (
    <div className="container">
      <h5>
        Pay over time with{" "}
        <img
          id="payment_method-affirm"
          src="https://cdn-assets.affirm.com/images/black_logo-white_bg.svg"
          height="30px"
          alt="Affirm"
        />
      </h5>
      <button className="btn btn-primary" onClick={handleAffirmCheckout}>
        Pay with Affirm now
      </button>
    </div>
  );
};

export default AffirmCheckout;
