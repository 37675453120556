import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Upload,
  Table,
  Divider,
  Modal,
  Descriptions,
  Select,
  Tag,
  Popover,
  Popconfirm,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import ReactQuill from "react-quill";
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  handleExcelExport,
  handleExcelFileUpload,
} from "../../../utilities/handleExcelOperations";
import roleAndPermissions from "../../../constants/permissions"
import AuthChecker from "../../../components/authChecker"
import VerifiedInstallerPageView from "../pages-view/VerifiedInstallerPageview";

const { TextArea } = Input;

const VerifiedInstallerForm = ({ mode, formFor, editLayout, title }) => {
  const [data, setData] = useState(formFor?.en?.data);
  const [locations, setLocations] = useState(data?.verified_location || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalDataIndex, setModalDataIndex] = useState(null);
  const [importedData, setImportedData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(false);

  const locationFormRef = useRef(null);

  const handleOk = () => {
    setLocations(importedData);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    values.verified_location = locations;
    // console.log(values);
    try {
      // values.banner1_image = UploadUtils.processUploadImage(
      //   values.banner1_image
      // );
      // values.banner2_image = UploadUtils.processUploadImage(
      //   values.banner2_image
      // );
      // // values.banner3_image = UploadUtils.processUploadImage(
      // //   values.banner3_image
      // // );

      // values.banner3_images = values.banner3_images.map((i) => ({
      //   image: UploadUtils.processUploadImage(i.image),
      // }));

      // values.body_banner_image = UploadUtils.processUploadImage(
      //   values.body_banner_image
      // );
      // values.why_you_choose = values.why_you_choose.map((i) => ({
      //   name: i.name,
      //   image: UploadUtils.processUploadImage(i.image),
      //   description: i.description,
      // }));

      // values.affiliations = values.affiliations.map((i) => ({
      //   affiliation_image: UploadUtils.processUploadImage(i.affiliation_image),
      // }));
      // values.tennessee = UploadUtils.processUploadImage(values.tennessee);
      // values.florida = UploadUtils.processUploadImage(values.florida);
      // values.texas = UploadUtils.processUploadImage(values.texas);

      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );

      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];
      setData(data);
      await editLayout(values, "Verified Installer", seo_setting);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const openModal = (mode, data, index) => {
    locationFormRef?.current?.resetFields();
    setModalDataIndex(index);
    setModalMode(mode);
    if (mode === "Edit" || mode === "View") {
      setModalData(data);
      locationFormRef?.current?.setFieldsValue(data);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    locationFormRef?.current?.resetFields();
    setModalData(null);
    setModalMode(null);
    setModalDataIndex(null);
    setIsModalOpen(false);
  };

  const handleAddLocation = (values) => {
    const newLocation = {
      ...values,
      key: locations.length ? locations[locations.length - 1].key + 1 : 0,
    };
    setLocations([...locations, newLocation]);
    closeModal();
  };

  const handleEditLocation = (values) => {
    let currentPage = current - 1;
    let currentIndex = pageSize * currentPage + modalDataIndex
    const updatedLocations = locations.map((loc, index) =>
      index === currentIndex ? { ...loc, ...values } : loc
    );
    setLocations(updatedLocations);
    closeModal();
  };

  const handleDeleteLocation = (index) => {
    let currentIndex;
    let currentPage = current - 1;
    if (current > 1) {
      currentIndex = pageSize * currentPage + index
    } else {
      currentIndex = index
    }
    const updatedLocations = locations.filter((_, idx) => idx !== currentIndex);
    setLocations(updatedLocations);
  };

  const handleFormSubmit = (values) => {
    if (modalMode === "Add") {
      handleAddLocation(values);
    } else if (modalMode === "Edit") {
      handleEditLocation(values);
    }
  };

  const handleExportClick = () => {
    const verifiedLocations = data?.verified_location || [];
    const allStatusesPresent = verifiedLocations.every(
      (entry) => entry.status && entry.status.trim() !== ""
    );
    if (!allStatusesPresent) {
      alert(
        "Some entries are missing the status field. Please check your data."
      );
      return;
    }
    handleExcelExport(verifiedLocations);
  };

  if (mode !== "View") {
    return (
      <>
        <Form
          disabled={mode === "View" || loading === true}
          layout="vertical"
          initialValues={data}
          onFinish={(values) => handleSubmit(values)}
        >
          <PageHeader title={`${title.replace("-", " ")} Page`} />
          <Row gutter={16} className="mt30">
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Verified Section" type="inner">
                <Form.Item
                  label="Verified Title"
                  name={["verified_installer", "verified_installer_title"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Verified Description"
                  name={["verified_installer", "verified_installer_description"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <ReactQuill theme="snow" rows={4} readOnly={mode === "View" ? true : false} />
                </Form.Item>
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="With Verified Installation Section" type="inner">
                <Form.Item
                  label="Verified Installation Title"
                  name={[
                    "with_verified_installation",
                    "with_verified_installation_title",
                  ]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Card size="small" title="With Verified Installation Point">
                  <Form.List
                    name={[
                      "with_verified_installation",
                      "with_verified_installation_point",
                    ]}
                  >
                    {(fields, { add, remove }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {fields.map((field, index) => (
                          <Flex key={field.key} gap={30}>
                            <Form.Item
                              label={`Verified Installation Point ${index + 1}`}
                              name={[field.name, "verified_installation_point"]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              style={{ flexGrow: 1 }}
                            >
                              <Input />
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Flex>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Verified Installation Point
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </Form.List>
                </Card>
              </Card>
            </Col>

            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Verified Installer Location Section" type="inner">
                <Form.Item
                  label="Location Title"
                  name="location_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Card size="small" style={{ marginBottom: "10px" }}>
                  <Flex justify="space-between" align="center">
                    <div>
                      <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={(event) =>
                          handleExcelFileUpload(event, setImportedData)
                        }
                        disabled={mode === "View" ? true : false}
                      />
                    </div>
                    <Flex justify="end" align="center" gap={8}>
                      <Button
                        size="small"
                        icon={<i className="ri-download-cloud-2-line"></i>}
                        disabled={importedData?.length === 0}
                        onClick={() => {
                          Modal.confirm({
                            title: "Are you sure?",
                            content: "This will overwrite your existing data.",
                            onOk: handleOk,
                            footer: (_, { OkBtn, CancelBtn }) => (
                              <>
                                <CancelBtn />
                                <OkBtn />
                              </>
                            ),
                          });
                        }}
                      >
                        Import data from chosen file
                      </Button>
                      <Button
                        size="small"
                        icon={<i class="ri-upload-2-line"></i>}
                        onClick={handleExportClick}
                      >
                        Export
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => openModal("Add", [], null)}
                      >
                        Add
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
                <Table
                  columns={[
                    {
                      title: "Location Title",
                      dataIndex: "location_title",
                      render: (value, record, index) => {
                        return (
                          <div>
                            <span>{value}</span>
                            <div style={{ display: "none" }}>
                              {/* <Form.Item
                              label="Location Title"
                              name={[
                                "verified_location",
                                index,
                                "location_title",
                              ]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Status"
                              name={["verified_location", index, "status"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Status is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Select
                                style={{
                                  width: "100%",
                                }}
                                options={[
                                  {
                                    value: "ACTIVE",
                                    label: "Active",
                                  },
                                  {
                                    value: "INACTIVE",
                                    label: "Inactive",
                                  },
                                ]}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Location Address"
                              name={[
                                "verified_location",
                                index,
                                "location_address",
                              ]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Phone Number"
                              name={[
                                "verified_location",
                                index,
                                "phone_number",
                              ]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Email"
                              name={["verified_location", index, "email"]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Website Url"
                              name={["verified_location", index, "website_url"]}
                              wrapperCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              shouldUpdate
                            >
                              <Input />
                            </Form.Item> */}
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Phone Number",
                      dataIndex: "phone_number",
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      render: (value, record, index) => {
                        return (
                          <Tag
                            color={
                              (value ?? "").toLowerCase() === "active"
                                ? "green"
                                : (value ?? "").toLowerCase() === "inactive"
                                  ? "red"
                                  : "default"
                            }
                          >
                            {value}
                          </Tag>
                        );
                      },
                    },
                    {
                      title: "Action",
                      dataIndex: "action",
                      align: "center",
                      render: (value, record, index) => {
                        return (
                          <Flex align="center" justify="center">
                            <Button
                              type="text"
                              onClick={() => openModal("View", record, index)}
                            >
                              View
                            </Button>
                            <Divider type="vertical" />
                            <Button
                              type="text"
                              onClick={() => openModal("Edit", record, index)}
                            >
                              Edit
                            </Button>
                            <Divider type="vertical" />
                            <Popconfirm
                              title="Are you sure?"
                              // description="Are you sure to delete this location?"
                              onConfirm={() => handleDeleteLocation(index)}
                              // onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type="text"
                                danger
                              // onClick={() => handleDeleteLocation(index)}
                              >
                                Delete
                              </Button>
                            </Popconfirm>
                          </Flex>
                        );
                      },
                    },
                  ]}
                  dataSource={locations}
                  pagination={{
                    pageSize: pageSize,
                    total: locations?.length,
                    current,
                    onChange: (page, pageSize) => {
                      setCurrent(page);
                      setPageSize(pageSize);
                    },
                  }}
                />
              </Card>
            </Col>
            <AuthChecker notPermissionCase={
              <Col span={24} style={{ marginBottom: 30 }}>
                <Card size="small" title="Search Engine Listing" type="inner">
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Meta Description"
                    name="meta_description"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <TextArea disabled />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Feature Image{" "}
                        <Popover
                          placement="right"
                          title="W * H"
                          content="1200 x 675 OR 1600 x 900"
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </span>
                    }
                    name="feature_image"
                    {...UploadUtils.formItemProps}
                    wrapperCol={{ span: 24 }}
                  >
                    <Upload {...UploadUtils.buttonPreviewProps} disabled>
                      <UploadUtils.CardContent />
                    </Upload>
                  </Form.Item>
                </Card>
              </Col>} permissions={[roleAndPermissions.PageManagement.createSEO, roleAndPermissions.PageManagement.updateSEO]}>

              <Col span={24} style={{ marginBottom: 30 }}>
                <Card size="small" title="Search Engine Listing" type="inner">
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Meta Description"
                    name="meta_description"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <TextArea />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        Feature Image{" "}
                        <Popover
                          placement="right"
                          title="W * H"
                          content="1200 x 675 OR 1600 x 900"
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </span>
                    }
                    name="feature_image"
                    {...UploadUtils.formItemProps}
                    wrapperCol={{ span: 24 }}
                  >
                    <Upload {...UploadUtils.buttonPreviewProps}>
                      <UploadUtils.CardContent />
                    </Upload>
                  </Form.Item>
                </Card>
              </Col>
            </AuthChecker>
            <Col span={24}>
              <Flex
                justify="end"
                style={{ padding: 24, paddingRight: 0 }}
                gap={16}
              >
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit" loading={loading}
                    disabled={loading}>
                    Update Page
                  </Button>
                </Form.Item>
              </Flex>
            </Col>
          </Row>
        </Form>
        <Modal
          title={<>{modalMode} Location</>}
          open={isModalOpen}
          onClose={closeModal}
          onCancel={closeModal}
          footer={null}
          width={700}
        >
          <div style={{ marginTop: 18 }}>
            {modalMode === "View" && (
              <Descriptions
                items={[
                  {
                    label: <b>Title</b>,
                    children: (
                      <b>{modalData?.location_title || "Not Available"}</b>
                    ),
                    span: 2,
                  },
                  {
                    label: <b>Phone Number</b>,
                    children: <b>{modalData?.phone_number || "Not Available"}</b>,
                    span: 1,
                  },
                  {
                    label: <b>Email</b>,
                    children: <b>{modalData?.email || "Not Available"}</b>,
                    span: 1,
                  },
                  {
                    label: <b>Website URL</b>,
                    children: <b>{modalData?.website_url || "Not Available"}</b>,
                    span: 2,
                  },
                  {
                    label: <b>Address</b>,
                    children: (
                      <b>{modalData?.location_address || "Not Available"}</b>
                    ),
                    span: 2,
                  },
                ]}
                column={2}
                bordered
              />
            )}
            {(modalMode === "Add" || modalMode === "Edit") && (
              <Form
                layout="vertical"
                // initialValues={modalData}
                onFinish={handleFormSubmit}
                ref={locationFormRef}
              >
                <Form.Item
                  label="Location Title"
                  name="location_title"
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Status is required",
                    },
                  ]}
                  initialValue="ACTIVE"
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    options={[
                      {
                        value: "ACTIVE",
                        label: "Active",
                      },
                      {
                        value: "INACTIVE",
                        label: "Inactive",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Location Address"
                  name="location_address"
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phone_number"
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                    {
                      pattern:
                        /^(\+1[-\s.]?)?(\(?\d{3}\)?[-\s.]?)?\d{3}[-\s.]?\d{4}$/,
                      message: "Please enter a valid phone number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email!",
                    },
                    {
                      validator: (_, value) => {
                        const forbiddenChars = /[+*!#$%^&()={}[\]:;'"<>,?/|\\~`]/;
                        if (value && forbiddenChars.test(value)) {
                          return Promise.reject(
                            "Email cannot contain special characters like +, *, !, #, $, etc."
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Website URL"
                  name="website_url"
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                    {
                      type: "url",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Latitude"
                      name="lat"
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="lng"
                      label="Longitude"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Flex align="center" justify="end" gap={16}>
                  <Button onClick={closeModal}>Cancel</Button>
                  <Form.Item noStyle>
                    <Button type="primary" htmlType="submit">
                      {modalMode === "Add" ? "Add Location" : "Update Location"}
                    </Button>
                  </Form.Item>
                </Flex>
              </Form>
            )}
          </div>
        </Modal>
      </>
    );
  }
  if (mode === "View") {
    return (
      <> <PageHeader title={`${title?.replace("-", " ")} Page`} />
        <VerifiedInstallerPageView data={data} />
      </>
    );
  }
};

export default VerifiedInstallerForm;
