import {
    Row,
    Col,
    Card,
    Descriptions,
    Image,
    Flex,
    Button,
    List,
    Avatar,
    Rate,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import "./AllCmsPage.css"

export default function ContactUsPageView({ data }) {
    return (
        <Row gutter={[16, 24]} className="mt30">
            <Col span={24}>
                <Card
                    size="small"
                    title="Contact Title"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Main Title</b>,
                                children: <>{data?.main_title}</>,
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Contact Details"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={3}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Advice, Sales & Support</b>,
                                children: <div>
                                    <p>Toll Free Number : {data?.advice_sales_support?.sales_toll_free_number}</p>
                                    <p>US Local Number : {data?.advice_sales_support?.us_local_number}</p>
                                </div>,
                                span: 1,
                            },
                            {
                                label: <b>Talk To Live</b>,
                                children: (
                                    <Flex align="center" wrap gap={12}>

                                        <div>
                                            <span>{data?.talk_to_live?.name} : </span>
                                            {
                                                data?.talk_to_live?.link_type === "internal" ?
                                                    <Link
                                                        to={
                                                            process.env.REACT_APP_CUSTOMER_SITE_URL +
                                                            data?.talk_to_live?.redirect_path
                                                        }
                                                        target="_blank"
                                                    >
                                                        <Button
                                                            size="small"
                                                            type="link"
                                                            style={{ padding: 0 }}
                                                        >
                                                            {data?.talk_to_live?.redirect_path}
                                                        </Button>
                                                    </Link> :
                                                    data?.talk_to_live?.link_type === "internal" ?
                                                        <Link
                                                            to={
                                                                data?.talk_to_live?.redirect_path
                                                            }
                                                            target="_blank"
                                                        >
                                                            <Button
                                                                size="small"
                                                                type="link"
                                                                style={{ padding: 0 }}
                                                            >
                                                                {data?.talk_to_live?.redirect_path}
                                                            </Button>
                                                        </Link> : null
                                            }
                                        </div>
                                    </Flex>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Wholesale</b>,
                                children: <div>
                                    <p>Toll Free Number : {data?.wholesale?.toll_free_number}</p>
                                </div>,
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Locations"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={3}
                        layout="vertical"
                        size="small"
                    >
                        {
                            data?.contact_locations_details?.map((location, idx) => {
                                return (
                                    <Descriptions.Item key={idx} style={{
                                        width: '100%',
                                        height: '100%',
                                    }}>
                                        <Card size="small" style={{ width: "100%", height: "100%", textAlign: 'center', }}>
                                            <Flex wrap gap={12} vertical>
                                                <Image
                                                    // width={200}
                                                    src={location?.location_image[0]?.url}
                                                    className="locationImage"
                                                    style={{
                                                        maxHeight: 80,
                                                        height: '100%'
                                                    }}
                                                />
                                                <b>{location?.name}</b>
                                                <span>{location?.subtitle}</span>
                                            </Flex>
                                        </Card>
                                    </Descriptions.Item>
                                )
                            })
                        }
                    </Descriptions>
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Search Engine Listing"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Meta Title</b>,
                                children: <>{data?.meta_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Meta Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.meta_description,
                                        }}
                                    >
                                        {/* {data?.banner_description} */}
                                    </div>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Banner Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.feature_image?.map((img) => (
                                            <Image width={100} src={img?.url} className="searchEngineImage" />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
        </Row >
    );
}
