import {
    Row,
    Col,
    Card,
    Descriptions,
    Image,
    Flex,
    Button,
    List,
    Avatar,
    Rate,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import "./AllCmsPage.css"

export default function FooterPageView({ data }) {
    return (
        <Row gutter={[16, 24]} className="mt30">
            <Col span={24}>
                <Card
                    size="small"
                    title="Address Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Address</b>,
                                children: <>{data?.address}</>,
                                span: 1,
                            },

                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Customer Care"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Customer Care Number</b>,
                                children: <>{data?.phone_number}</>,
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Accepted Payments"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Accepted Payment Images</b>,
                                children: (
                                    <Flex align="center" wrap gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.accepted_payments?.map((img) => (
                                            <Image
                                                width={100}
                                                src={img?.payment_image?.[0]?.url}
                                                className="homeAffiliationImage"
                                            />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Social media Link"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={2}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Instagram Link</b>,
                                children: <>{<Link to={data?.instagram_link} target="_blank">{data?.instagram_link}</Link>}</>,
                                span: 2,
                            },
                            {
                                label: <b>Facebook Link</b>,
                                children: <>{<Link to={data?.facebook_link} target="_blank">{data?.facebook_link}</Link>}</>,
                                span: 2,
                            },
                            {
                                label: <b>Youtube Link</b>,
                                children: <>{<Link to={data?.youtube_link} target="_blank">{data?.youtube_link}</Link>}</>,
                                span: 2,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="About Links"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={2}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>About Link</b>,
                                children: (
                                    <Flex align="center" wrap gap={12}>
                                        {data?.about_links?.map((about) => (
                                            <Card size="small">
                                                <Flex
                                                    justify="center"
                                                    align="center"
                                                    gap={8}
                                                    style={{ width: "100%" }}
                                                >
                                                    <Flex vertical>
                                                        <b>{about?.link_name}</b>

                                                        {/* <Link
                                                            to={
                                                                process.env.REACT_APP_CUSTOMER_SITE_URL +
                                                                about?.redirect_path
                                                            }
                                                            target="_blank"
                                                        >
                                                            <Button
                                                                size="small"
                                                                type="link"
                                                                style={{ padding: 0 }}
                                                            >
                                                                {" "}
                                                                {about?.redirect_path}
                                                            </Button>
                                                        </Link> */}

                                                        {
                                                            about?.link_type === "internal" ? (
                                                                <Link
                                                                    to={process.env.REACT_APP_CUSTOMER_SITE_URL + about?.redirect_path}
                                                                    target="_blank"
                                                                >
                                                                    <Button size="small" type="link" style={{ padding: 0 }}>
                                                                        {about?.redirect_path}
                                                                    </Button>
                                                                </Link>
                                                            ) : about?.link_type === "external" ? (
                                                                <Link
                                                                    to={about?.redirect_path}
                                                                    target="_blank"
                                                                >
                                                                    <Button size="small" type="link" style={{ padding: 0 }}>
                                                                        {about?.redirect_path}
                                                                    </Button>
                                                                </Link>
                                                            ) : null
                                                        }

                                                    </Flex>
                                                </Flex>
                                            </Card>
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Important Links"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={2}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Important Link</b>,
                                children: (
                                    <Flex align="center" wrap gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.important_links?.map((important) => (
                                            <Card size="small">
                                                <Flex
                                                    justify="center"
                                                    align="center"
                                                    gap={8}
                                                    style={{ width: "100%" }}
                                                >
                                                    <Flex vertical>
                                                        <b>{important?.link_name}</b>

                                                        {/* <Link
                                                            to={
                                                                process.env.REACT_APP_CUSTOMER_SITE_URL +
                                                                important?.redirect_path
                                                            }
                                                            target="_blank"
                                                        >
                                                            <Button
                                                                size="small"
                                                                type="link"
                                                                style={{ padding: 0 }}
                                                            >
                                                                {" "}
                                                                {important?.redirect_path}
                                                            </Button>
                                                        </Link> */}

                                                        {
                                                            important?.link_type === "internal" ? (
                                                                <Link
                                                                    to={
                                                                        process.env.REACT_APP_CUSTOMER_SITE_URL +
                                                                        important?.redirect_path
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    <Button size="small" type="link" style={{ padding: 0 }}>
                                                                        {important?.redirect_path}
                                                                    </Button>
                                                                </Link>
                                                            ) : important?.link_type === "external" ? (
                                                                <Link
                                                                    to={important?.redirect_path}
                                                                    target="_blank"
                                                                >
                                                                    <Button size="small" type="link" style={{ padding: 0 }}>
                                                                        {important?.redirect_path}
                                                                    </Button>
                                                                </Link>
                                                            ) : null
                                                        }

                                                    </Flex>
                                                </Flex>
                                            </Card>
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Customer Counting Section"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={3}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Total Number of Customer</b>,
                                children: <>{data?.total_customer}</>,
                                span: 1,
                            },
                            {
                                label: <b>Customer Section Title</b>,
                                children: <>{data?.customer_section_title}</>,
                                span: 2,
                            },
                            {
                                label: <b>Customer Section Description</b>,
                                children: <>{data?.customer_section_description}</>,
                                span: 3,
                            },

                        ]}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    size="small"
                    title="Search Engine Listing"
                    type="inner"
                    bodyStyle={{ padding: 24 }}
                >
                    <Descriptions
                        column={1}
                        layout="vertical"
                        size="small"
                        // bordered
                        items={[
                            {
                                label: <b>Meta Title</b>,
                                children: <>{data?.meta_title}</>,
                                span: 1,
                            },
                            {
                                label: <b>Meta Description</b>,
                                children: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data?.meta_description,
                                        }}
                                    >
                                        {/* {data?.banner_description} */}
                                    </div>
                                ),
                                span: 1,
                            },
                            {
                                label: <b>Banner Image</b>,
                                children: (
                                    <Flex align="center" gap={12}>
                                        {/* {data?.banner_description} */}
                                        {data?.feature_image?.map((img) => (
                                            <Image width={100} src={img?.url} className="searchEngineImage" />
                                        ))}
                                    </Flex>
                                ),
                                span: 1,
                            },
                        ]}
                    />
                </Card>
            </Col>
        </Row>
    );
}
