import {
  Row,
  Col,
  Card,
  Descriptions,
  Image,
  Flex,
  Button,
  List,
  Avatar,
  Rate,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import "./AllCmsPage.css"

export default function HomePageView({ data }) {
  return (
    <Row gutter={[16, 24]} className="mt30">
      <Col span={24}>
        <Card
          size="small"
          title="Banner Section"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Descriptions
            column={1}
            layout="vertical"
            size="small"
            // bordered
            items={[
              {
                label: <b>Banner Title</b>,
                children: <>{data?.banner_title}</>,
                span: 1,
              },
              {
                label: <b>Banner Description</b>,
                children: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.banner_description,
                    }}
                  >
                    {/* {data?.banner_description} */}
                  </div>
                ),
                span: 1,
              },
              {
                label: <b>Banner Image</b>,
                children: (
                  <Flex align="center" gap={12}>
                    {/* {data?.banner_description} */}
                    {data?.banner_image?.map((img) => (
                      <Image width={100} src={img?.url} className="homeBannerImage" />
                    ))}
                  </Flex>
                ),
                span: 1,
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title="Services Section"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Descriptions
            column={1}
            layout="vertical"
            size="small"
            // bordered
            items={[
              {
                label: <b>Services Section Title</b>,
                children: <>{data?.services_title}</>,
                span: 1,
              },
              {
                label: <b>Services</b>,
                // children: (
                //   <Flex align="center" wrap gap={12}>
                //     {/* {data?.banner_description} */}
                //     {data?.services?.map((s) => (
                //       <Card size="small">
                //         <Flex
                //           justify="center"
                //           align="center"
                //           gap={8}
                //           style={{ width: "100%" }}
                //         >
                //           <Image width={60} src={s?.service_image?.[0]?.url} className="serviceImage" />
                //           <Flex vertical>
                //             <b>{s?.service_name}</b>
                //             <Link
                //               to={
                //                 process.env.REACT_APP_CUSTOMER_SITE_URL +
                //                 s?.redirect_path
                //               }
                //               target="_blank"
                //             >
                //               <Button
                //                 size="small"
                //                 type="link"
                //                 style={{ padding: 0 }}
                //               >
                //                 {" "}
                //                 {s?.redirect_path}
                //               </Button>
                //             </Link>
                //           </Flex>
                //         </Flex>
                //       </Card>
                //     ))}
                //   </Flex>
                // ),

                children: (
                  <Row gutter={[16, 16]}>
                    {data?.services?.map((s) => (
                      <Col span={8}>
                        <Card size="small" style={{ height: "100%" }}>
                          <Flex align="center" gap={8}>
                            <Image width={60} src={s?.service_image?.[0]?.url} className="serviceImage" />
                            <Flex vertical>
                              <b>{s?.service_name}</b>
                              <Link to={process.env.REACT_APP_CUSTOMER_SITE_URL + s?.redirect_path} target="_blank">
                                <Button
                                  size="small"
                                  type="link"
                                  style={{ padding: 0 }}
                                >
                                  {" "}
                                  {s?.redirect_path}
                                </Button>
                              </Link>
                            </Flex>
                          </Flex>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ),
                span: 1,
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title="Affiliations Section"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Descriptions
            column={1}
            layout="vertical"
            size="small"
            // bordered
            items={[
              {
                label: <b>Affiliation Section Title</b>,
                children: <>{data?.affiliation_title}</>,
                span: 1,
              },
              {
                label: <b>Affiliation Images</b>,
                children: (
                  <Flex align="center" wrap gap={12}>
                    {/* {data?.banner_description} */}
                    {data?.affiliations?.map((img) => (
                      <Image
                        width={100}
                        src={img?.affiliation_image?.[0]?.url}
                        className="homeAffiliationImage"
                      />
                    ))}
                  </Flex>
                ),
                span: 1,
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title="Video Section"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Descriptions
            column={2}
            layout="vertical"
            size="small"
            // bordered
            items={[
              {
                label: <b>Main Title</b>,
                children: <>{data?.youtube_main_title}</>,
                span: 1,
              },
              {
                label: <b>Sub Title</b>,
                children: <>{data?.youtube_sub_title}</>,
                span: 1,
              },
              {
                label: <b>Video URL</b>,
                children: <Link to={data?.youtube_link?.redirect_path} target="_blank">{data?.youtube_link?.redirect_path}</Link>,
                span: 2,
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title="Trust Our Work Section"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Descriptions
            column={1}
            layout="vertical"
            size="small"
            // bordered
            items={[
              {
                label: <b>Trust Our Work Section Title</b>,
                children: <>{data?.trust_our_work_title}</>,
                span: 1,
              },
              {
                label: <b>Our Works</b>,
                // children: (
                //   <Flex align="center" wrap gap={12}>
                //     {/* {data?.banner_description} */}
                //     {data?.our_works?.map((s) => (
                //       <Card size="small">
                //         <Flex justify="center" align="center" gap={8} className="trustImageContainer">
                //           <Image width={60} src={s?.work_image?.[0]?.url} className="trustImage" />
                //           <b>{s?.work_name}</b>
                //         </Flex>
                //       </Card>
                //     ))}
                //   </Flex>
                // ),
                children: (
                  <Row gutter={[16, 16]}>
                    {data?.our_works?.map((s) => (
                      <Col span={8}>
                        <Card size="small" style={{ height: "100%" }}>
                          <Flex align="center" gap={8}>
                            <Image width={60} src={s?.work_image?.[0]?.url} className="trustImage" />
                            <b>{s?.work_name}</b>
                          </Flex>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ),
                span: 1,
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title="Benefits For WholeSalers Section"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Descriptions
            column={1}
            layout="vertical"
            size="small"
            // bordered
            items={[
              {
                label: <b>Section Title</b>,
                children: <>{data?.wholeSalers_section_title}</>,
                span: 1,
              },
              {
                label: <b>Section Description</b>,
                children: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.wholeSalers_section_description,
                    }}
                  ></div>
                ),
                span: 1,
              },
              {
                label: <b>Benefits</b>,
                // children: (
                //   <Flex align="center" wrap gap={12}>
                //     {/* {data?.banner_description} */}
                //     {data?.wholesalers_benefits?.map((s) => (
                //       <Card size="small">
                //         <Flex justify="center" align="center" gap={8}>
                //           <Image width={60} src={s?.benefit_image?.[0]?.url} className="benefitsImage" />
                //           <b>{s?.benefit_name}</b>
                //         </Flex>
                //       </Card>
                //     ))}
                //   </Flex>
                // ),

                children: (
                  <Row gutter={[16, 16]}>
                    {data?.wholesalers_benefits?.map((s) => (
                      <Col span={8}>
                        <Card size="small" style={{ height: "100%" }}>
                          <Flex align="center" gap={8}>
                            <Image width={60} src={s?.benefit_image?.[0]?.url} className="benefitsImage" />
                            <b>{s?.benefit_name}</b>
                          </Flex>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ),
                span: 1,
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title="Benefits For Jeep Build Section"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Descriptions
            column={2}
            layout="vertical"
            size="small"
            // bordered
            items={[
              {
                label: <b>Section Title</b>,
                children: <>{data?.jeep_build_section_title}</>,
                span: 2,
              },
              {
                label: <b>Section Description</b>,
                children: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.jeep_build_section_description,
                    }}
                  ></div>
                ),
                span: 2,
              },
              {
                label: <b>Benefits</b>,
                children: (
                  <ul>
                    {data?.jeep_build_benefits?.map((b) => (
                      <li>{b?.benefit_name}</li>
                    ))}
                  </ul>
                ),
                span: 1,
              },
              {
                label: <b>Section Image</b>,
                children: (
                  <Image
                    width={200}
                    src={data?.jeep_build_section_image?.[0]?.url}
                  />
                ),
                span: 1,
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title="Body Banner Section"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Descriptions
            column={3}
            layout="vertical"
            size="small"
            // bordered
            items={[
              {
                label: <b>Body Banner Title</b>,
                children: <>{data?.body_banner_title}</>,
                span: 3,
              },
              {
                label: <b>Body Banner Description</b>,
                children: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.body_banner_description,
                    }}
                  ></div>
                ),
                span: 2,
              },
              {
                label: <b>Section Image</b>,
                children: (
                  <Image width={200} src={data?.body_banner_image?.[0]?.url} />
                ),
                span: 1,
              },
            ]}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title="Testimonial Section"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Flex vertical gap={24}>
            <Descriptions
              column={1}
              layout="vertical"
              size="small"
              // bordered
              items={[
                {
                  label: <b>Testimonial Section Title</b>,
                  children: <>{data?.testimonial_section_title}</>,
                  span: 1,
                },
              ]}
            />
            <List
              bordered
              itemLayout="horizontal"
              dataSource={data?.testimonials}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <span>Rating: {item?.customer_rating}</span>,
                    <span>
                      {dayjs(item?.testimonial_date).format("YYYY-MM-DD")}
                    </span>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item?.customer_image?.[0]?.url}>
                        {item?.customer_name?.charAt(0)}
                      </Avatar>
                    }
                    title={<span>{item?.customer_name} </span>}
                    description={item?.customer_testimonial}
                  />
                </List.Item>
              )}
            />
          </Flex>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          size="small"
          title="Search Engine Listing"
          type="inner"
          bodyStyle={{ padding: 24 }}
        >
          <Descriptions
            column={1}
            layout="vertical"
            size="small"
            // bordered
            items={[
              {
                label: <b>Meta Title</b>,
                children: <>{data?.meta_title}</>,
                span: 1,
              },
              {
                label: <b>Meta Description</b>,
                children: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.meta_description,
                    }}
                  >
                    {/* {data?.banner_description} */}
                  </div>
                ),
                span: 1,
              },
              {
                label: <b>Banner Image</b>,
                children: (
                  <Flex align="center" gap={12}>
                    {/* {data?.banner_description} */}
                    {data?.feature_image?.map((img) => (
                      <Image width={100} src={img?.url} className="searchEngineImage" />
                    ))}
                  </Flex>
                ),
                span: 1,
              },
            ]}
          />
        </Card>
      </Col>
    </Row>
  );
}
